.course-sidebar-container {
  position: relative;
  height: 22rem;
  background-color: #fff;
  border-radius: 1rem;
}

.course-sidebar-icon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.course-sidebar-chapter-container {
  align-items: center;
  font-weight: bolder;
  width: 20rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  font-size: 2rem;
  background-color: #ececec;
  border-radius: 15px;
  width: 94%;
}

.course-sidebar-chapter-title {
  display: flex;
  align-items: center;
  font-weight: bolder;
  padding: 0.5rem 1rem;
  font-size: 2rem;
  background-color: #ececec;
  border-radius: 10px;
  display: flex;
  cursor: pointer;
}

.is-slides-container {
  background-color: white;
}

.new-chapter {
  font-weight: bolder;
  width: 20rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  font-size: 2rem;
  background-color: #ececec;
  border-radius: 10px;
  width: 94%;
}

.course-sidebar-status {
  text-align: center;
  margin-top: 3rem;
}

.course-sidebar-status-icon {
  width: 5rem;
  height: 5rem;
}

.one-slide-container {
  padding: 0.5rem;
  width: 15rem;
  height: 12rem;
  background-color: #ececec;
  margin-bottom: 2rem;
  cursor: pointer;
  overflow: hidden;
}

.selected {
  margin-left: 3.5rem;
  cursor: pointer;
  height: 12rem;
}

.one-slide-image {
  width: 15rem;
  height: 12rem;
}

.new-slide-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: 15rem;
  height: 12rem;
  background-color: #ececec;
  margin-bottom: 2rem;
  cursor: pointer;
}

.new-slide-image {
  width: 5rem;
  height: 5rem;
}

.one-slide-container p {
  font-size: 1rem;
}

.p-chapter-title {
  margin-left: 1rem;
  word-break: break-word;
}

.new-quiz {
  font-weight: bolder;
  width: 94%;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  font-size: 2rem;
  background-color: #ececec;
  border-radius: 10px;
}

.course-sidebar-quiz-container {
  align-items: center;
  font-weight: bolder;
  width: 94%;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  font-size: 2rem;
  background-color: #ececec;
  border-radius: 15px;
}

.course-sidebar-quiz-title {
  display: flex;
  align-items: center;
  font-weight: bolder;
  padding: 0.5rem 1rem;
  font-size: 2rem;
  background-color: #ececec;
  border-radius: 10px;
  display: flex;
  cursor: pointer;
}

.is-questions-container {
  background-color: white;
}

.new-question-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: 15rem;
  height: 12rem;
  background-color: #ececec;
  margin-bottom: 2rem;
  cursor: pointer;
}

.new-question-image {
  width: 5rem;
  height: 5rem;
}

.one-question-container {
  padding: 0.5rem;
  width: 15rem;
  height: 12rem;
  background-color: #ececec;
  margin-bottom: 2rem;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-quiz-title {
  word-break: break-word;
}

@media only screen and (max-width: 1280px) and (min-height: 800px) {
  .course-sidebar-chapter-container,
  .new-chapter {
    width: 88%;
  }
}
