.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 4;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.modal-content h2 {
  margin-bottom: 2rem;
}

/* delete background style */
.delete-background {
  background-color: rgb(234 103 103 / 80%) !important;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #ffffff;
  padding: 14px 28px;
  border-radius: 20px;
  max-width: 100rem;
  height: 22rem;
  z-index: 5;
}


.slide-type-popup-container {
  height: 50%;
  width: 60%;
  text-align: center;
}

.slide-type-popup {
  display: flex;
  text-align: center;
}


/* start slide text image */

.model-text {
  padding-top: 2rem;
  font-weight: bold;
}

.slide-text-image-type {
  flex: 1;
  cursor: pointer;
}

.slide-text-image {
  width: 45rem;
  height: 35rem;
}

/* end slide text image */

/* start slide video type */

.slide-video-type {
  flex: 1;
  cursor: pointer;
}

/* end slide video type */

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}



.popup-btns {
  padding-top: 9rem;
  display: flex;
  justify-content: flex-end;
}





