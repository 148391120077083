.help-popup-modal-content {
  position: absolute;
  width: 34.5%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(32, 31, 31);
  border-radius: 2.8125rem;
  z-index: 5;
}

/*Start popup Header */
.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.help-popup-header-title {
  padding: 40px 0px 0px 40px;
  display: flex;
  flex-direction: column;
  font-size: 34px;
  font-weight: bold;
  line-height: 100%;
  color: white;
}

.help-popup-header-image {
  padding: 20px 20px 0px 0px;
  display: flex;
  justify-content: flex-end;
}

.help-popup-header-image img {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
/* End help popup Header */

/* Start help popup body */

.help-popup-body {
  padding: 0px 40px 40px 40px;
}
/* Start Contrast Container */

.contrast-font-size-container {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  /* margin-bottom: 10px; */

  /* height: 10rem; */
}

.contrast-container {
  padding: 20px;
  width: 41.5%;
  /* width: 46%;
  height: 26vh; */
  background-color: rgb(52, 51, 51);
  border-radius: 8px;
  margin-right: 1rem;
}

.contrast-title {
  line-height: 100%;
  color: white;
  font-size: 22px;
}

.contrast-images {
  display: flex;
  justify-content: space-between;
  padding: 40px 20px 0px 20px;
}

.contrast-images img {
  width: 40%;
  cursor: pointer;
}

.contrast-check-marks-icons {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 14%;
  padding-right: 14%;
}

.check-marks-icons {
  width: 25%;
  cursor: pointer;
  /* height: 25%; */
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
/* 
.contrast-mode-check-marks-icons{
 width: 25%;
cursor: pointer;
height: 25%;

} */

/* End Contrast Container */

/* start Font Size Container */

.font-size-container {
  padding: 20px;
  width: 41.5%;
  /* width: 48.28%;
  height: 28.5vh; */
  background-color: rgb(52, 51, 51);
  border-radius: 8px;
  margin-left: 10px;
}

.font-size-title {
  line-height: 100%;
  color: white;
}

.font-size-letters {
  display: flex;
  /* changed temporary by Hassan 9-5-2022 */
  justify-content: space-around;
  /* justify-content: space-between; */
  align-items: baseline;
  margin-top: 20%;
  margin-right: 5%;
  margin-left: 5%;
}

.letter1 {
  font-size: 22px;
  font-weight: bold;
  color: white;
  /* margin-left: 20px; */
  cursor: pointer;
}

.letter2 {
  font-size: 28px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.letter3 {
  font-size: 34px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.letter4 {
  font-size: 40px;
  font-weight: bold;
  color: white;
  /* padding-right: 26px; */
  cursor: pointer;
}

.font-size-images {
  display: flex;
  /* changed temporary by Hassan 9-5-2022 */
  justify-content: space-around;
  /* justify-content: space-between; */
  margin-top: 6%;
  /* margin-right: 2rem; */
}

.font-size-check-marks-icons {
  width: 18%;
  cursor: pointer;
  /* height: 15%; */
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

/* End Font Size Container */

/* Start Language Container */

.language-container {
  background-color: rgb(52, 51, 51);
  margin-top: 3.3%;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  /* padding-right: 3%;
  padding-left: 3%; */
}

.language-icon-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.language-icon-title img {
  width: 15%;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-right: 5%;
}

.language-title {
  color: white;
}

.language-check-icon img {
  width: 45px;
  height: 45px;
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-top: 36%;
  margin-bottom: 36%;
}

/* end Language container */

/* start contrast */

body.enable-contrast,
body.enable-contrast .background-color {
  background-color: black !important;
}

body.enable-contrast .border {
  border: 2px solid #fff;
}

body.enable-contrast .border-right {
  border-right: 2px solid #fff;
}

body.enable-contrast .black-border {
  border: 2px solid black;
}

body.enable-contrast .border-left {
  border-left: 2px solid black;
}

body.enable-contrast .MuiSvgIcon-root {
  color: #d1a703;
}

body.enable-contrast .header-logo,
body.enable-contrast .text-content {
  color: #fff;
}

body.enable-contrast .change-hr-bg-color {
  background-color: #fff;
}

body.enable-contrast .error-content {
  color: red;
}

body.enable-contrast .list-row {
  border-bottom: 1px solid #fff;
}

body.enable-contrast .header-btn {
  color: #fff;
  border: 1px solid #fff;
}

/* end contrast */

/* Tablet version */
@media only screen and (max-height: 600px) {
  .help-popup-modal-content {
    width: 50%;
  }
}
@media only screen and (max-width: 916px) {
  .help-popup-modal-content {
    width: 60%;
  }
}

@media only screen and (max-width: 700px) {
  .help-popup-modal-content {
    width: 65%;
  }
}

/* phone version */
@media only screen and (max-width: 650px) {
  .help-popup-modal-content {
    width: 80%;
  }
}

@media only screen and (max-width: 550px) {
  .help-popup-modal-content {
    width: 85%;
  }
}

@media only screen and (max-width: 450px) {
  .help-popup-modal-content {
    width: 80%;
  }

  .help-popup-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contrast-font-size-container {
    flex-direction: column;
    align-items: center;
  }

  .contrast-container {
    width: 90%;
    height: 125px;
    margin: 0;
  }

  .contrast-images {
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .contrast-images img {
    width: 30%;
  }

  .font-size-container {
    width: 90%;
    height: 125px;
    margin: 0;
    margin-top: 7.25px;
  }

  .font-size-letters {
    margin-top: 20px;
  }

  .language-container {
    padding-left: 15px;
  }

  .language-icon-title img {
    width: 30%;
  }

  .language-container {
    width: 94%;
    padding-right: 15px;
  }
}

@media screen and (max-height: 500px) {
  .help-popup-header-title h1 {
    font-size: 26px;
  }
  .help-popup-header-image img {
    width: 40px;
    height: 40px;
  }
  .help-popup-modal-content {
    width: 80% !important;
  }

  .help-popup-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .contrast-font-size-container {
    justify-content: center;
  }
  .contrast-container {
    width: 30%;
  }

  .font-size-container {
    width: 30%;
  }

  .font-size-letters{
    margin-top: 35px !important;
  }

  .language-container {
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    margin-left: 10px;
    padding-top: 0;
    height: 160px;
  }

  .text-content {
    font-size: 13px;
  }

  .language-icon-title{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .language-title{
    margin-bottom: 16px;
  }

  .language-icon-title img{
    width: 40%;
    margin-top: 20px !important;
  }

  .language-check-icon img{
    width: 16px !important;
    height: 16px !important;
    margin-top: 16px !important;
  }
}
