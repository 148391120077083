.course-location-section {
  background-color: #ececec;
  padding: 1.5rem;
  width: 93%;
}

.course-location-section-title {
  margin-bottom: 2rem;
}

.course-location-section-title h1 {
  font-size: 2.2rem;
  font-weight: bold;
}

.course-location-learning-modules {
  padding: 0 2rem;
}

.course-detail-learning-module-name {
  font-size: 3.5rem;
  font-weight: 900;
}

.course-detail-subject-name {
  font-size: 2rem;
  font-weight: 500;
  margin-left: 2rem;
}

.course-location-bold-subject-name {
  font-weight: 700;
  font-size: 2.5rem;
  display: flex;
  align-items: center;
}

.course-location-bold-subject-icon {
  margin-left: 1rem;
  width: 3.3rem;
  height: 3.3rem;
}
