/* new 16-2-2022 */
.user-overview-subjects-container {
  display: flex;
  width: 100%;
  min-height: 100vh;
}

.user-overview-subjects-sidebar {
  margin-left: 1.04%;
  margin-right: 1.04%;
  width: 19.53%;
  position: sticky;
  height: 100vh;
  overflow: auto;
  top: 0px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.user-overview-subjects-sidebar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.user-overview-subjects-sidebar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.user-overview-subjects-body {
  width: 88.5%;
}

.user-learning-module-thumbnail-container {
  display: flex;
  height: 300px;
  width: 100%;
  margin-bottom: 15px;
  align-items: center;
}

.user-learning-module-video-container {
  display: flex;
  height: 300px;
  width: 100%;
  margin-bottom: 15px;
  flex-direction: column;
  justify-content: center;
}

.learning-module-content {
  padding: 0 6.47%;
}

.user-subject-name {
  display: inline-block;
  margin-top: 80px;
  margin-left: 40px;
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: bold;
  line-height: 1;
}

.user-subject-courses-list {
  display: flex;
  flex-wrap: wrap;
}

.user-one-course {
  flex: 0 0 31.22%;
  background-color: #fff;
  border-radius: 25px;
  margin: 20px 0;
  margin-right: 3.16%;
}

.user-one-course:nth-child(3n + 3) {
  margin-right: 0 !important;
}

.course-icon-desc-container {
  display: flex;
  padding: 7px;
  align-items: center;
  cursor: pointer;
}

.user-course-icon {
  width: 33.65%;
}

.course-title-container {
  line-height: 1;
  padding: 7px 0;
  padding-left: 10px;
  width: 56%;
}

/* we may need this one later */
/* .course-last-visited-div {
  text-align: center;
  margin-bottom: -30px;
  font-size: 16px;
} */

.user-help-logo {
  position: fixed;
  right: 20px;
  top: 20px;
  width: 50px;
  height: 50px;
  z-index: 3;
  cursor: pointer;
}

.learning-module-name-above-video {
  border-radius: 80px;
  position: absolute;
  font-size: 40px;
  font-weight: bold;
  padding: 25px;
  z-index: 3;
  margin-left: 5.2%;
}
/* .learning-module-name-above-video {
  margin: 1rem;
  border-radius: 50px;
  position: absolute;
  font-size: 22px;
  font-weight: bold;
  padding: 1rem 2rem;
  z-index: 3;
} */

.create-edit-module-subject-body
  .video-controls-container.full-screen
  .pretto-slider-container {
  width: 45%;
}

.user-speaker-icon {
  width: 50px;
  height: 50px;
  margin-left: -6rem;
  margin-bottom: -1.1rem;
  cursor: pointer;
}

.user-overview-subjects-burger-menu {
  display: none;
}

.user-overview-subjects-sidebar-header {
  display: none;
}

/* Tablet version */


@media only screen and (max-width: 915px) {
  .user-overview-subjects-container-hidden {
    height: 100%;
    overflow: hidden;
  }
  .user-overview-subjects-sidebar {
    display: none;
  }

  .user-overview-subjects-sidebar.show {
    display: block;
    position: absolute;
    z-index: 7;
    width: 100%;
    background-color: #fff;
    margin-left: 0;
    height: 100%;
  }

  .user-overview-subjects-sidebar-header {
    width: 96%;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    background-color: #fff;
    padding: 0 20px;
  }

  .user-overview-subjects-sidebar-header-shadow {
    width: 96%;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    background-color: #fff;
    padding: 0 20px;
    box-shadow: 0px 15px 5px rgba(0, 0, 0, 0.1);
  }

  .user-overview-subjects-sidebar-header-title {
    font-weight: bold;
    font-size: 30px;
  }

  .user-overview-subjects-sidebar-header-close-button {
    width: 6rem;
    height: 6rem;
  }

  .user-overview-subjects-burger-menu {
    width: 96%;
    height: 80px;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 4;
    background-color: #c4c6c6;
  }

  .user-overview-subjects-burger-menu-icon {
    width: 6rem;
    height: 6rem;
  }

  .user-overview-subjects-burger-menu-title {
    font-weight: bold;
    font-size: 30px;
    color: #fff;
  }

  .user-learning-module-thumbnail-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
  }

  .learning-module-name-above-video {
    font-size: 36px;
  }

  .user-overview-subjects-body {
    width: 100%;
  }

  .learning-module-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .user-subject-courses-list {
    display: block;
  }

  .user-speaker-icon {
    display: none;
  }

  .add-shadow {
    box-shadow: 0px 10px 20px 20px rgb(0 0 0 / 20%);
  }
}

/* phone version */

@media only screen and (max-width: 600px) {
  .user-overview-subjects-sidebar-header {
    width: 93%;
  }

  .user-overview-subjects-sidebar-header-title {
    font-size: 22px;
  }

  .user-overview-subjects-sidebar-header-close-button {
    width: 50px;
    height: 50px;
  }

  .user-overview-subjects-burger-menu {
    width: 93%;
  }

  .user-overview-subjects-burger-menu-title {
    font-size: 22px;
  }

  .user-overview-subjects-burger-menu-icon {
    width: 50px;
    height: 50px;
  }

  .learning-module-name-above-video {
    font-size: 28px;
  }
}

@media only screen and (max-width: 415px) {
  .user-overview-subjects-sidebar-header {
    width: 90%;
  }

  .user-overview-subjects-burger-menu {
    width: 90%;
  }
}

@media only screen and (max-width: 300px) {
  .user-overview-subjects-sidebar-header {
    width: 84%;
  }

  .user-overview-subjects-burger-menu {
    width: 84%;
  }

  .user-learning-module-thumbnail-container {
    text-align: center;
  }

  .course-title-container {
    font-size: 22px;
  }
}
