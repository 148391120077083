.controlsWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
}

.controlIcons {
  transform: scale(0.9);
}

.controlIcons:hover {
  color: #fff;
  transform: scale(1);
}

.bottomIcons {
  color: #999;
}

.bottomIcons:hover {
  color: #fff;
}

.volumeSlider {
  width: 100px !important;
}

.custom-icon {
  width: 2rem;
  height: 2rem;
}

.duration-time {
  padding-top: 2%;
  padding-left: 15px;
  padding-right: 20px;
}

.bottom-control {
  padding: 16px;
}

.caption-icon,
.fullscreen-icon {
  width: 100%;
}

.panorma-icon {
  width: 3rem;
  height: 2rem;
}

.tool-tip {
  font-size: 1.5rem;
}

.controlsWrapper .MuiSvgIcon-root {
  color: #fff !important;
}

.video-title {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 5rem;
  color: #fff;
}

.video-title .text {
  background-color: #26a0c0;
  padding: 1rem 1rem;
  border-radius: 50px;
  font-size: 4rem;
}

.control-icon {
  color: #fff;
}

.control-icon.play {
  font-size: 5rem !important;
}

.control-icon.play.bottom {
  font-size: 3rem !important;
  width: 100%;
}

.play-container {
  width: 18%;
}

.play-back {
  font-size: 2rem !important;
  color: #333;
}

.control-icon.size {
  font-size: 2rem;
  color: black;
}

.video-controls-container {
  display: flex;
  flex-wrap: wrap;
  background-color: #f4f4f4;
  border-radius: 45px;
  padding: 15px 15px 10px 15px;
  opacity: 0;
}

.hide-video-controls-container {
  display: none;
}

/* start full screen styles */
.video-controls-container.full-screen,
.home-page-video-container .video-controls-container.full-screen {
  max-width: 1040px;
  margin: 20px auto;
  padding: 15px 15px 6px 15px;
}

.video-controls-container.full-screen .play-container {
  width: 8%;
}

.video-controls-container.full-screen .caption-icon-container {
  width: 4%;
}

.video-controls-container.full-screen .fullscreen-icon-container {
  width: 4%;
}

.video-controls-container.full-screen .pretto-slider-container {
  padding-top: 6px;
  width: 50%;
  margin-left: 60px;
}

.video-controls-container.full-screen .duration-time {
  padding-top: 1%;
  padding-left: 15px;
  padding-right: 20px;
  margin-right: 40px;
}

.fast-forward-icon-container {
  width: 4%;
}

.fast-rewind-icon,
.fast-forward-icon {
  width: 100%;
}

.fast-rewind-icon-container {
  margin-left: 40px;
  margin-right: 20px;
  width: 4%;
}

/* end full screen styles */
.pretto-slider-container {
  padding-top: 2%;
  margin-left: 8%;
  width: 35%;
}

.caption-icon-container {
  width: 9.5%;
  padding-right: 20px;
}

.fullscreen-icon-container {
  width: 9.5%;
}

.controlsWrapper:hover .video-controls-container {
  opacity: 1;
}

/* add border around the videos when onFocus */
.controlsWrapper:focus {
  outline: 4px solid rgb(107, 206, 231);
}
/* this class is used onKeyPress */
.video-controls-container:focus-within {
  opacity: 1;
}

/* Tablet version */
@media only screen and (max-width: 1280px) {
  .video-controls-container {
    justify-content: center;
    align-items: center;
  }
  .fast-rewind-icon-container {
    margin-left: 20px;
  }

  .pretto-slider-container {
    margin-left: 30px !important;
    width: 40% !important;
  }

  .duration-time {
    margin-right: 20px !important;
  }
}

/* phone version */
@media only screen and (max-width: 600px) {
  .control-icon.play.bottom {
    width: 40px;
  }

  .fast-rewind-icon-container {
    margin-left: 25px;
  }

  .fast-rewind-icon,
  .fast-forward-icon,
  .fullscreen-icon,
  .caption-icon {
    width: 25px;
  }

  .fullscreen-icon-container {
    width: 7% !important;
  }

  .pretto-slider-container {
    margin-left: 35px !important;
    /* width: 40% !important; */
  }

  .duration-time {
    margin-right: 0 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media only screen and (max-width: 400px) {
  .control-icon.play.bottom {
    width: 35px;
  }

  .fast-rewind-icon-container {
    margin-left: 20px;
  }

  .fast-rewind-icon,
  .fast-forward-icon,
  .fullscreen-icon,
  .caption-icon {
    width: 20px;
  }

  .pretto-slider-container {
    margin-left: 25px !important;
    width: 15% !important;
  }

  .duration-time {
    margin-right: 0 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
