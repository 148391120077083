.create-edit-course-chapter-slide-container {
  display: flex;
  min-height: 100vh;
}

.create-edit-course-chapter-slide-sidebar {
  padding: 3rem;
  background-color: #c4c2c2;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 300px;
}

.create-edit-course-chapter-slide-body {
  flex: 4;
  padding: 3rem;
  margin-left: 3rem;
}

.editor-course-name-container {
  width: 93%;
  background-color: #ececec;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.editor-course-name {
  background-color: white;
  width: 100%;
  font-size: 30px;
  font-weight: bold;
  color: #26a0c0;
}

.close-course-icon {
  top: 1rem;
}

.help-course-icon {
  top: 7rem;
}

.slide-content-container {
  width: 93%;
  background-color: #ececec;
  min-height: 65rem;
  padding: 1rem;
  text-align: center;
  position: relative;
}

.nav-slides {
  position: absolute;
  bottom: -80px;
  left: 50%;
  padding: 0 20px;
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.slide-content-container .nav-slides {
  bottom: -120px;
}

.slide-content-container p {
  font-size: 3rem;
}

.slide-video-container {
  width: 935;
  height: 400px;
  background-color: #c4c2c2;
}

.delete-slide-icon {
  width: 4rem;
  height: 4rem;
  position: absolute;
  right: 13rem;
  cursor: pointer;
  margin-top: 3rem;
}

.rdw-editor-main {
  overflow: auto;
  box-sizing: border-box;
  height: 40rem !important;
  background-color: white;
}

@media only screen and (max-width: 2560px) and (min-height: 1440px) {
  .create-edit-course-chapter-slide-sidebar {
    flex-basis: 350px;
  }
}

@media only screen and (max-width: 1550px) {
  .create-edit-course-chapter-slide-sidebar {
    flex-basis: 245px;
  }

  .nav-slides {
    bottom: -70px;
  }
}

@media only screen and (max-width: 1280px) and (min-height: 800px) {
  .create-edit-course-chapter-slide-sidebar {
    flex-basis: 167px;
  }

  .course-sidebar-container {
    height: 18rem;
  }

  .icon-input p {
    left: 3rem;
  }

  .new-chapter .MuiInputBase-input,
  .p-title {
    font-size: 16px;
  }
}
