.editor-quiz-questions-answers-main-container {
  width: 93%;
  display: flex;
  flex-direction: column;
}

.course-title-quiz-p {
  font-size: 4rem;
}

.course-quiz-titles-div {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  background-color: #ececec;
  width: 94.5%;
}
/* Quiz Text Section */

.editor-quiz-text-container {
  display: flex;
  flex-direction: column;
  width: 93%;
  background-color: #ececec;
  padding: 1rem;
  margin-bottom: 3rem;
}

.editor-quiz-text-container h3 {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

/* Answer Section */

.editor-quiz-answers-section {
  width: 93%;
  display: flex;
  flex-direction: column;
}

.editor-quiz-answers-section h3 {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.answer-switch-div {
  display: flex;
  justify-content: center;
  margin-top: -4rem;
  margin-bottom: 2.5rem;
}

/* Quiz Answer Text Container */

.editor-quiz-answers-text-container {
  width: 102%;
  display: flex;
  flex-wrap: wrap;
}

.editor-quiz-one-answer {
  display: flex;
  width: 49.5%;
  background-color: #ececec;
  margin-right: 1rem;
  margin-bottom: 1rem;
  font-size: 2.3rem;
}

.editor-quiz-one-answer:nth-child(2n + 2) {
  margin-right: 0 !important;
}

.answer-text-image {
  flex: 7;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.answer-text-image img {
  text-align: center;
  width: 70%;
  height: 100%;
}

.answer-wrong-flag {
  flex: 1;
  background-color: rgb(255, 216, 209);
  padding: 2rem;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  max-width: 5rem;
  min-width: 5rem;
}

.answer-wrong-flag p {
  font-size: 20px;
  color: red;
  font-weight: bold;
}

.answer-right-flag {
  flex: 1;
  background-color: rgb(203, 255, 209);
  padding: 2rem;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  max-width: 5rem;
  min-width: 5rem;
}

.answer-right-flag p {
  font-size: 20px;
  color: green;
  font-weight: bold;
}

/* New Answer Text */

.new-answer-text {
  flex: 7;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  cursor: pointer;
}

.new-answer-text img {
  width: 6rem;
  height: 6rem;
}

/* New Answer Image */

.new-answer-image {
  flex: 7;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  height: 15rem;
}

.new-answer-image img {
  width: 6rem;
  height: 6rem;
}


