.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 4;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.delete-background {
  background-color: rgb(234 103 103 / 80%) !important;
}

.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #ffffff;
  padding: 14px 28px;
  border-radius: 20px;
  max-width: 50rem;
  height: 22rem;
  z-index: 5;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}

.modal-content h2 {
  margin-bottom: 2rem;
}

.popup-btns {
  padding-top: 9rem;
  display: flex;
  justify-content: flex-end;
}

.model-text {
  padding-top: 2rem;
  font-weight: bold;
}

