* {
  margin: 0;
}

html {
  font-size: 62.5%;
  text-rendering: optimizeLegibility;
  --primaryBackground: #0c9ec7;
  --secondayBackground: #494949;
  --primaryTextColor: #333;
}

@font-face {
  font-family: "Frutiger";
  src: local("Frutiger"),
    url(./assets/Fonts/Frutiger_LT_55_Roman.ttf) format("truetype"),
    url(./assets/Fonts/Frutiger_LT_55_Roman.woff) format("woff"),
    url(./assets/Fonts/Frutiger_LT_55_Roman.woff2) format("woff2");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  margin: 0;
  font-family: "Frutiger";
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  color: var(--primaryTextColor);
  height: 100%;
  position: relative;
}

.main-container {
  /* min-height: 100vh;
  overflow: hidden; */
  display: block;
  position: relative;
  height: 100vh;
}

h1 {
  font-size: 5.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  text-decoration: none;
  display: inline;
  text-transform: inherit;
}

a {
  background-color: transparent;
  outline: none;
  text-decoration: none;
}

code {
  font-family: "Frutiger";
}

.header-logo {
  font-size: 3.2rem;
}

.validation-error-message {
  color: red;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.sub-title {
  font-size: 3rem;
}

.sub-title span {
  padding-right: 1rem;
}

/* add the font size classNames */
.fontSize-22 {
  font-size: 22px;
}

.fontSize-28 {
  font-size: 28px;
}

/* common table structure
--------------------------------------------- */

.wrapper {
  max-width: 1180px;
  position: relative;
  padding: 0 20px;
  margin: auto;
}

.wrapper.list {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.list-items {
  margin-top: 20px;
  font-size: 1.6rem;
}

.list-item {
  padding: 20px 19px 20px 19px;
  width: 15%;
}

.list-row:nth-child(odd) {
  background-color: #0c9ec70d;
}

.create-table {
  display: table;
}

.create-row {
  display: table-row;
}

.create-cell {
  display: table-cell;
}

.full-width {
  width: 100%;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.svg-icon {
  transform: scale(1.8);
  color: var(--primaryTextColor);
  padding-top: 18px;
  cursor: pointer;
}

.detele-icon {
  margin: 0 20px;
}

.add-button {
  margin-right: 24px;
}

.list-item.header-title {
  font-weight: bold;
  font-size: 2rem;
}

.list-title {
  font-size: 3rem;
  color: var(--primaryBackground);
  font-weight: bold;
}

.tool-tip {
  font-size: 1rem;
}

/* end common table structure
--------------------------------------------- */

/* common form style
--------------------------------------------- */
.create-edit-container {
  max-width: 750px;
  margin: auto;
  padding: 80px 20px;
}

.create-edit-form {
  padding: 20px 73px 30px 60px;
  box-shadow: 0 10px 13px 0 rgba(0, 0, 0, 0.2);
}

.error-message {
  color: red;
}

.form-title {
  font-size: 2rem;
  padding-bottom: 30px;
}

.form-input {
  padding-top: 15px;
}

.form-label {
  padding-right: 1rem;
}

.form-field input {
  height: 35px;
  padding-left: 10px;
  width: 98%;
  border: 1px solid #dbdbdb;
  margin-top: 10px;
  font-size: 2rem;
}

.submit-container {
  margin-top: 2rem;
}

.form-input.react-select input:focus {
  border: none !important;
}

.required-star {
  color: red;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/* body.active-modal{
  overflow: hidden;
} */

/* end common form style
--------------------------------------------- */
