/* same design from Create module subject */

.create-edit-module-subject-container {
  display: flex;
}

.create-edit-module-subject-body.filtered {
  flex: 4;
  margin-left: 1rem;
  background-color: #ececec;
  padding: 3rem 5rem;
}

.my-one-course{
  width: 28.4%;
  background-color: #fff;
  margin-right: 2%;
  border-radius: 20px;
  margin-top: 3rem;
  padding: 2rem;
  position: relative;
}

.my-one-course:nth-child(3n + 3) {
  margin-right: 0 !important;
}

.create-edit-module-subject-body.filtered .course-icon {
  width: 15rem;
  height: 15rem;
}

.create-edit-module-subject-body.filtered .course-desc {
  padding-left: 2rem;
  font-size: 2rem;
  font-weight: bold;
  color: #42ccce;
  padding-left: 2rem;
}

.course-desc-container,
.course-icon-container {
  width: 50%;
  cursor: pointer;
}

/* Add the design for the course status */
.filtered-learning-module-title {
  font-weight: bold;
  font-size: 4rem;
}

.my-learning-module {
  margin-top: 3rem;
  font-size: 4rem;
  font-weight: bolder;
}

.my-container {
  display: flex;
  flex-wrap: wrap;
}

.in-edit-box {
  background-color: rgb(250, 249, 215);
}

.published-box {
  background-color: rgb(207, 228, 186);
}

.deleted-box {
  background-color: rgb(250, 215, 215);
}

@media screen and (max-width: 1300px) {
  .my-one-course:nth-child(2n + 2) {
    margin-right: 0 !important;
  }
}
