.course-right-sidebar {
  display: flex;
  flex-direction: column;
}

.course-right-sidebar-icons {
  width: 4.5rem;
  height: 4.5rem;
  position: absolute;
  right: 2rem;
  cursor: pointer;
}

.delete-course-icon {
  bottom: 10%;
}

.translation-link-icon {
  bottom: 50%;
}
