.my-button {
  padding: 1rem 2rem;
  margin: 0.5rem;
  color: white;
  font-size: 2.5rem;
  border: solid;
  border-radius: 0.3rem;
  background-color: #169fc5;
  cursor: pointer;
  border-radius: 3rem;
}

.my-button.disabled {
  cursor: default;
}

/*delete,published,cancel buttons style */
.delete-button {
  background-color: #ff0000c7 !important;
  border-radius: 3rem;
  font-size: 2rem;
}

.publish-button {
  background-color: green !important;
  font-size: 2rem;
  border-radius: 3rem;
}

.cancel {
  background-color: #000000b3;
  border-radius: 3rem;
  font-size: 2rem;
}

.change-button {
  background-color: green;
}

.cancel-reset-password-button {
  background-color: #000000b3;
}

