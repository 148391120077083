.statistic-container {
  display: flex;
}

.statistic-sidebar {
  margin-left: 1.04%;
  margin-right: 1.04%;
  width: 19.53%;
}

.statistic-body {
  flex: 4;
  padding-left: 1rem;
}

.bar-chart {
  display: flex;
}

.pie-chart-text {
  margin-left: 2rem;
}

@media only screen and (max-width: 2560px) and (min-height: 1440px) {
  .statistic-sidebar {
    flex-basis: 412px;
  }
}

@media only screen and (max-width: 1550px) {
  .statistic-sidebar {
    flex-basis: 310px;
  }
}

@media only screen and (max-width: 1280px) and (min-height: 800px) {
  .statistic-sidebar {
    flex-basis: 230px;
  }
}
