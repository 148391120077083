.login-container {
  background-color: #a9d5e9;
  min-height: 85vh;
}

.login {
  padding: 0 1rem;
  margin: 8% 25% 19%;
}

.login-hr {
  margin: 1.3rem 0;
}

.login-label {
  width: 8rem;
  font-size: 2.5rem;
  font-weight: bold;
  padding-right: 1rem;
}

.login-form input {
  width: 100%;
}

.login-btn {
  text-align: center;
  margin: 0.5rem 0;
}

.admin-login-input {
  width: 100%;
}

.admin-login-input input {
  width: 100%;
  margin-left: 3.5rem;
  margin-top: 2rem;
}

.username-section{
display: flex;
align-items: baseline;
}

.password-section{
  display: flex;
  align-items: baseline;
}

.login-title {
  font-size: 6rem;
  font-weight: bolder;
  text-align: center;
}

.login-form-title {
  font-size: 4rem;
  font-weight: bolder;
  display: block;
}

.text-above-login-form {
  font-size: 2.5rem;
  font-weight: bold;
}

.login-error-message {
  color: red;
  margin-left: 3.5rem;
}