.list-item.translation-text {
    width: 66%;
}

.list-item.translation-term {
    width: 18%;
}

.detele-icon {
    width: 3%;
}

.delete-translation-icon {
    width: 100%;
    margin-top: 3rem;
    cursor: pointer;
}

.translations.list {
    flex: 4 1;
    padding: 20px;
}

.translations .list-item.header-title {
    font-size: 3rem;
}

.tanslations-main {
    display: flex;
}

.translations.list .list-title {
    font-size: 6rem;
    color: #333;
    font-weight: normal;
    padding-left: 1.1rem;
}

.new-translation-container {
    border: solid 5px #ececec;
    width: 30rem;
    border-radius: 20px;
    margin-top: 20px;
    cursor: pointer;
}

.new-translation-inner {
    display: flex;
    align-items: center;
}

textarea:focus {
    border: 2px solid #169fc5 !important;
    outline: none !important;
}

