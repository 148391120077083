.change-password.modal-content {
  padding: 14px 40px 14px 28px;
  width: 600px;
}

.form-image input {
  display: none;
  background-color: transparent;
}

.image-preview {
  width: 10rem;
  height: 10rem;
}

.popup-upload-buttons {
  display: flex;
  justify-content: flex-end;
}

.translation-text-area {
  width: 100%;
  height: 10rem;
  margin-top: 10px;
  resize: none;
}

.translation-text-area {
  padding-left: 10px;
  width: 98%;
  border: 1px solid #dbdbdb;
  margin-top: 10px;
  font-weight: 400;
  font-family: "Frutiger";
  font-size: 2rem;
  padding-top: 1rem;
}

