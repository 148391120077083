.header {
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.header1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header1 h1 {
  color: white;
  font-size: x-large;
  font-weight: bold;
  text-decoration: none;
}

.header2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header2.help-info p {
  margin-right: 1em;
}

.header3 {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.header3 p {
  margin-right: 2rem;
  font-weight: bold;
}

.header-logo {
  margin: 0.5rem;
  padding: 0.8rem 1rem;
  background: transparent;
  font-size: 4rem;
  font-weight: bold;
  text-decoration: none;
  color: black;
}


.header-help-logo {
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  margin-right: 2rem;
}
.header-tts-logo {
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  margin-right: 2rem;
}

.header-close-button {
  width: 5rem;
  height: 5rem;
  display: flex;
  cursor: pointer;
}

.header-btn {
  margin: 0.5rem;
  padding: 0.8rem 1rem;
  background: transparent;
  border: solid;
  border-width: 0.1rem;
  border-radius: 0.3rem;
  border-color: rgb(0, 0, 0);
  font-size: x-large;
  font-weight: bold;
  text-decoration: none;
  color: black;
}

.header-hr {
  margin-bottom: 0.5rem;
  background-color: #494949;
}

.fontSize-lang-indicator {
  font-weight: bold;
}

/* tablet version */

@media only screen and (max-width: 800px){
    .header {
    height: 80px;
    padding: 0;
  }

  .header3 .header-close-button {
    width: 6rem;
    height: 6rem;
  }

  .header3 .header-help-logo{
    width: 6rem;
    height: 6rem;
  }

  .header3 .text-content{
    color: #fff !important;
  }
}

/* phone version */
@media only screen and (max-width: 600px){
  .header3 .header-close-button {
    width: 50px;
    height: 50px;
  }

  .header3 .header-help-logo{
    width: 50px;
    height: 50px;
  }
}

@media only screen and (max-width: 430px){
  .header3 .text-content{
    margin-top: 55px;
    font-size: 12px;
  }
}