.green-yes-icon {
  width: 5rem;
  height: 5rem;
}

.video-percentage-green-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.none {
  display: none;
}

.vtt-green-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.vtt-yes-icon {
  width: 5rem;
  height: 5rem;
}
