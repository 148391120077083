.change-password.modal-content {
  padding: 14px 40px 14px 28px;
  width: 600px;
}

.form-image input {
  display: none;
  background-color: transparent;
}

.image-preview {
  width: 10rem;
  height: 10rem;
  cursor: pointer;
}

.popup-upload-buttons {
  display: flex;
  justify-content: flex-end;
}
