/*----Main container----- */

.user-quiz-main-page-container {
  padding-top: 112px;
  padding-bottom: 4%;
  min-height: 80%;
  position: relative;
  max-width: 100%;
  overflow-x: hidden;
  animation: hide-scroll 3s;
}

/* remove scrollbar that appears through CSS animation/transition */
@keyframes hide-scroll {
  from,
  to {
    overflow: hidden;
  }
}

/* ----Wissens-Quiz Design---- */

.user-quiz-title {
  position: absolute;
  top: 32px;
  left: 50%;
  transform: translate(-50%, 0);
}

.user-quiz-title h1 {
  font-size: 4rem;
  font-weight: bold;
  line-height: 125%;
}

.user-quiz-title-for-3sec {
  display: flex;
  justify-content: center;
  padding: 25% 0;
  font-size: 80px;
  font-weight: bold;
  line-height: 100%;
}

/* ----Question Container---- */

.user-quiz-question-container {
  margin: 0 15.4%;
  background-color: white;
  border-radius: 40px;
}

.user-quiz-question-container:after {
  content: "";
  position: absolute;
  margin-left: 225px;
  top: 1rem;
  border-right: 120px solid transparent;
  border-bottom: 106px solid #fff;
}

/* ----Question-Answer Container---- */

.user-quiz-question-answer-container {
  display: flex;
  flex-direction: column;
}

/* ----Question's Header----*/

.user-quiz-question-header-container {
  display: flex;
  justify-content: space-between;
}

.user-quiz-question-section {
  padding: 40px 0px 0px 40px;
  display: flex;
  flex-direction: column;
  width: 604px;
}

.user-quiz-question-section h1,
.user-quiz-question-section h2 {
  font-size: 40px;
  font-weight: bold;
}

.user-quiz-question-section h1 {
  line-height: 100%;
}

.user-quiz-help-close-buttons-section {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.user-quiz-help-close-buttons-section img {
  width: 50px;
  height: 50px;
}

.quiz-header-sound-button {
  cursor: pointer;
}

.quiz-header-help-logo {
  padding-left: 20px;
  cursor: pointer;
}

.quiz-header-close-button {
  padding: 0px 20px;
  cursor: pointer;
}

/* ----Answer Section---- */

.user-quiz-answer-container {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  border-radius: 12px;
}

.user-quiz-answer-text-content-container {
  justify-content: space-between;
  padding: 0px 40px;
}

.user-quiz-answer-image-content-container {
  padding: 0 3%;
  /* padding-right: 40px; */
}

.user-quiz-answer-text-right-answer {
  margin-top: -0.7rem;
}

.user-quiz-answer-image-right-answer {
  margin-bottom: 10%;
}

/* #thumb-up-image,#thumb-down-image {
  position: absolute;
 height: 24vh; 
} */

.user-quiz-right-answer {
  height: 20vh;
  transform: translateX(-100%);
  transition: transform 1s ease-in-out;
}

.user-quiz-wrong-answer {
  margin-top: -13%;
  transform: translateX(100%);
  transition: transform 1s ease-in-out;
}

.user-quiz-answer-text-wrong-answer {
  margin-top: -0.7rem;
}

.user-quiz-answer-image-wrong-answer {
  margin-bottom: 10%;
}

.user-quiz-right-answer img {
  width: 56.5%;
}

.user-quiz-wrong-answer img {
  width: 56.5%;
}

#thumb-up-image.active .user-quiz-right-answer {
  transform: translate(0);
}

#thumb-down-image.active .user-quiz-wrong-answer {
  transform: translate(43.6%);
}

/* ----Text Answer---- */

.user-quiz-one-answer-text {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 48.4%;
  min-height: 14vh;
  border-radius: 12px;
  background-color: #ececec;
  cursor: pointer;
}

.user-quiz-one-answer-text:nth-child(2n + 2) {
  margin-right: 0 !important;
}

.user-quiz-one-answer-text p {
  padding: 30px 0px 27px 20px;
  line-height: 125%;
}

.user-quiz-one-answer-text h3 {
  margin-left: 10px;
}

/* ----Image Answer---- */

.user-quiz-one-answer-image {
  margin-bottom: 40px;
  margin-left: 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 31.3%;
  border-radius: 12px;
  cursor: pointer;
  background-color: #ececec;
}

.user-quiz-one-answer-image:nth-child(3n + 3) {
  margin-left: 0;
}

/* ---Check Answer---- */
.wrong-selected {
  box-shadow: 0px 0px 0px 5px rgb(233, 45, 45);
}

.correct-selected {
  box-shadow: 0px 0px 0px 5px rgb(38, 167, 38);
}

.user-quiz-one-answer-image .user-quiz-answer-image {
  width: 71%;
  /* height: 17.5rem; */
  border-radius: 12px;
}

/* -----Indicator----- */
.user-quiz-one-answer-text .user-quiz-answer-selected-indicator {
  height: 50px;
  margin: 0px 20px 0px 40px;
}

body.enable-contrast
  .user-quiz-one-answer-text
  .user-quiz-answer-selected-indicator {
  border: 2px solid black;
  border-radius: 50%;
}

.user-quiz-one-answer-image .user-quiz-answer-selected-indicator {
  width: 50px;
  height: 50px;
  padding: 0px 20px;
}

.user-quiz-check-answer-button-container-center {
  display: flex;
  justify-content: center;
}

.user-quiz-check-answer-button-container {
  display: flex;
  justify-content: space-between;
}

.user-quiz-check-answer-button-container-end {
  display: flex;
  justify-content: flex-end;
}

/* ---------------------- */

/* ----Quiz buttons----- */

.user-quiz-button {
  font-weight: bold;
  line-height: 100%;
  color: white;
  border: 0;
  border-radius: 40px;
  cursor: pointer;
  padding: 15px;
  display: block;
  margin-left: auto;
  margin-bottom: 40px;
}

.user-quiz-check-answer-button {
  margin-right: auto;
}

.user-quiz-next-question-button {
  margin-right: 40px;
}

.user-quiz-repeat-question-button {
  margin-left: 40px;
}

/* ------Transcript Container to be changed------ */

.user-quiz-transcript-container {
  display: flex;
  flex-direction: column;
}

.user-quiz-transcript {
  display: flex;
  justify-content: flex-end;
}

.user-quiz-transcript-section {
  padding: 40px 0px 0px 40px;
  display: flex;
  flex-direction: column;
  width: 40%;
  font-size: 4rem;
  font-weight: bold;
  line-height: 100%;
  white-space: pre-line;
}

.user-quiz-transcript-min-height {
  min-height: 48vh;
}

.user-quiz-transcript img {
  /* width: 64.5rem; */
  height: 60vh;
  margin-top: -8rem;
}

.user-quiz-check-transcript-button-container {
  display: flex;
  justify-content: space-between;
}

.user-quiz-check-transcript-button {
  margin-right: 42%;
}

/* Tablet version */
@media only screen and (max-width: 915px) {
  .user-quiz-main-page-container {
    height: 100%;
  }

  .user-quiz-question-container {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 120px;
  }

  .user-quiz-question-container:after {
    margin-top: 120px;
  }

  .quiz-header-sound-button {
    display: none;
  }

  .user-quiz-right-answer {
    height: 200px;
  }

  .user-quiz-answer-container {
    justify-content: space-between;
  }

  .user-quiz-one-answer-image {
    width: 48.3%;
    margin-left: 0;
  }

  .user-quiz-one-answer-text {
    padding-left: 5px;
  }

  .user-quiz-transcript img {
    width: 600px;
    height: 600px;
    margin-top: 0;
  }

  .user-quiz-check-transcript-button-container {
    margin-top: 20px;
  }

  .user-quiz-check-transcript-button {
    margin-right: 40px;
  }
}

/* phone version */

@media only screen and (max-width: 600px) {
  .user-quiz-title-for-3sec {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 70px;
  }
  .user-quiz-title h1 {
    font-size: 30px;
  }

  .user-quiz-question-container {
    margin-top: 55px;
  }
  .user-quiz-question-container::after {
    margin-top: 70px;
    margin-left: 130px;
  }

  .user-quiz-question-section h1,
  .user-quiz-question-section h2 {
    font-size: 36px;
  }

  .user-quiz-right-answer {
    height: 100px;
  }

  .user-quiz-answer-selected-indicator {
    padding-left: 0 !important;
    margin-left: 0 !important;
  }

  .user-quiz-check-answer-button {
    font-size: 18px;
  }

  .user-quiz-repeat-question-button {
    margin-left: 20px;
    font-size: 18px;
  }
  .user-quiz-next-question-button {
    margin-right: 20px;
    font-size: 18px;
  }

  .user-quiz-answer-text-content-container {
    padding: 0 3%;
  }

  .user-quiz-one-answer-text {
    width: 47.4%;
  }

  .user-quiz-one-answer-text h3 {
    font-size: 16px;
  }

  .user-quiz-transcript img {
    width: 400px;
    height: 400px;
  }
  .user-quiz-check-transcript-button-container {
    margin-top: 20px;
  }

  .user-quiz-check-transcript-button {
    margin-right: 20px;
    font-size: 18px;
  }

  .user-quiz-transcript-section h2 {
    font-size: 26px;
  }

  .user-quiz-one-answer-text h3 {
    margin-left: 5px;
  }
}

@media only screen and (max-width: 470px) {
  .user-quiz-title h1 {
    font-size: 30px;
  }

  .user-quiz-question-header-container {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .user-quiz-help-close-buttons-section {
    width: 100%;
    z-index: 3;
  }

  .user-quiz-question-section {
    width: 80%;
    height: auto;
    padding-left: 0;
    padding-top: 0;
  }

  .user-quiz-question-container {
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 25px;
  }

  .user-quiz-question-container::after {
    margin-top: 70px;
    margin-left: 75px;
  }

  .user-quiz-help-close-buttons-section img {
    width: 40px;
    height: 40px;
  }

  .user-quiz-question-section {
    z-index: 1;
  }

  .user-quiz-question-section h1,
  .user-quiz-question-section h2 {
    font-size: 24px;
  }

  .user-quiz-right-answer {
    height: 100px;
  }

  .user-quiz-answer-container {
    justify-content: center;
  }

  .user-quiz-one-answer-image {
    width: 98%;
    margin-bottom: 15px;
  }

  .user-quiz-check-answer-button-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .user-quiz-check-answer-button {
    width: 80%;
  }

  .user-quiz-repeat-question-button {
    margin: 0;
    width: 80%;
    margin-bottom: 15px;
  }

  .user-quiz-next-question-button {
    margin: 0;
    width: 80%;
    margin-bottom: 40px;
  }

  .user-quiz-check-answer-button-container-end {
    justify-content: center;
  }

  .user-quiz-one-answer-text {
    width: 98%;
    margin-bottom: 15px;
  }

  .user-quiz-transcript-section h2 {
    font-size: 26px;
    z-index: 1;
  }

  .user-quiz-transcript img {
    width: 200px;
    height: 200px;
  }

  .user-quiz-check-transcript-button-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .user-quiz-check-transcript-button {
    width: 80%;
    margin: 0;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 384px) {
  .user-quiz-title {
    width: 100%;
    text-align: center;
  }

  .user-quiz-question-container {
    margin-top: 20px;
  }

  .user-quiz-question-container::after {
    margin-top: 60px;
  }

  .user-quiz-transcript-section {
    width: 80%;
    padding: 0;
  }
}

/* phone rotate */
@media only screen and (max-height: 599px) {
  .user-quiz-transcript img {
    width: 250px;
    height: 250px;
  }
}
