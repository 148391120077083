.course-title-p {
  font-weight: bolder;
  font-size: 4rem;
  max-width: 1000px;
}

.editor-chapter-name-container {
  display: flex;
  width: 93%;
  background-color: #ececec;
  padding: 1rem;
  margin-bottom: 2rem;
  align-items: center;
}

.editor-chapter-name-container p {
  color: #26a0c0;
  font-size: 25px;
  margin-right: 0.5rem;
  font-weight: bold;
}

.editor-course-name-container h1 {
  font-size: 1.8rem;
  font-weight: bold;
}


