.course-container {
  display: flex;
  min-height: 100vh;
  width: 100%;
}
/* Course Sidebar*/

.course-container-sidebar {
  flex-basis: 19.53%;
  flex-grow: 0;
  flex-shrink: 0;
  position: sticky;
  height: 100vh;
  overflow: auto;
  top: 0px;
}

.sidebar-course-icon {
  width: 17.1rem;
  height: 17.1rem;
  border-radius: 2rem;
  display: block;
  margin: 11% auto 11% auto;
}

/* Header of the to bi view only in the tablet and phone version */
.course-container-sidebar-header {
  display: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.course-container-sidebar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.course-container-sidebar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*Course Body*/

.course-container-body {
  width: 88.5%;
  /* margin: 0 5.6%; */
  padding: 0 5.6%;
  height: 100vh;
  overflow: auto;
  top: 0px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.course-container-body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.course-container-body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*Course Container*/

.course-container-main {
  position: relative;
}

.course-user-header {
  position: absolute;
  right: 0;
  top: 0;
}

.course-container-inner1 {
  margin-top: 1.5rem;
  position: relative;
}

.course-container-body .header-hr {
  border: none;
  background-color: #fff;
}

.enable-contrast .course-container-body .header-hr {
  display: none;
}

.course-title {
  display: inline-block;
  padding: 1.5rem 0 0.3rem 4rem;
  line-height: 1.5em;
}

.course-details-burger-menu {
  display: none;
}

.course-title .text {
  font-family: sans-serif;
  font-weight: lighter;
  max-width: 550px;
}

/* start Chapter */

.chapter-title-top {
  font-weight: bolder;
}

.chapter-title {
  margin: 0 5% 1rem 5%;
  font-weight: bold;
  padding: 1rem 6%;
  cursor: pointer;
  color: #fff;
  line-height: 1.2em;
  border-radius: 1rem;
}

.user-slide-video-container {
  background-color: #c4c2c2;
  border-radius: 2rem;
}

.user-slide-video-container .controlsWrapper {
  border-radius: 2rem;
}

.user-text-image-container {
  display: flex;
  background-color: rgb(241, 241, 241);
  border-radius: 2rem;
  height: 75vh;
}

.user-slide-text {
  padding: 2.5% 3%;
  flex: 1;
  /* max-height: 45rem; */
  padding-bottom: 2rem;
  overflow-y: auto;
  line-height: 1.5em;
  /* max-height: 55rem; */
  overflow-y: auto;
  scrollbar-width: thin;
  /*firefox*/
}

.user-slide-image-translation-container {
  width: 50%;
}

.user-slide-image-translation-container-2 {
  max-width: 50%;
}

.user-slide-image {
  /* width: 100%; */
  /* height: 100%; */
  float: right;
  max-height: 100%;
  max-width: 100%;
  border-radius: 25px;
  flex: 1;
}

.user-slide-translation-div {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  flex: 1;
}

/* start dictionary popup */

.no-image-slide {
  background-color: rgb(230, 228, 228);
}

.translated-section-container {
  box-shadow: 0px 0px 15px 15px rgba(0, 0, 0, 0.3);
  opacity: 1;
  background-color: white;
  overflow: auto;
}

.my-opacity {
  opacity: 0.6;
}

.user-translation-popup-dictionary {
  font-size: 40px;
  font-weight: bold;
  font-feature-settings: normal;
  font-variant: normal;
  line-height: 100%;
  float: right;
  margin-top: 3rem;
  margin-right: 20rem;
}

.translation-text-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.translated-term-header {
  padding: 40px 40px;
  flex: 3;
}

.translated-term-header p {
  font-weight: bold;
}

.translated-term-slide-popup {
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
}

.translation-text-popup-header2 {
  padding: 20px 20px;
}

.translated-text-speaker-button {
  cursor: pointer;
  width: 50px;
  height: 50px;
  padding: 0px 20px;
  flex: 1;
}

.translated-text-close-button {
  cursor: pointer;
  width: 50px;
}

.translated-text-user-popup {
  padding: 0px 40px;
  line-height: 125%;
}
/* end dictionary popup */

.user-slide-navigator-start-quiz-button-container {
  display: flex;
  justify-content: center;
  margin: 3% 0;
}

/* start quiz button */
.user-start-quiz-button-container {
  /* display: flex;
  justify-content: flex-end;
  margin: 3rem 0;
  margin-left: 5rem;
  align-items: center; */
  /*  */
  display: flex;
  position: absolute;
  right: 0;
  justify-content: flex-end;
  margin: 0;
  margin-left: 8rem;
  align-items: center;
}

.user-start-quiz-button {
  /* padding: 2rem;
  border: none;
  border-radius: 30px;
  font-size: 3rem;
  font-weight: bold;
  color: white;
  padding-left: 7rem;
  cursor: pointer; */
  /*  */
  padding: 0.8rem;
  border: none;
  border-radius: 30px;
  font-size: 3rem;
  font-weight: bold;
  color: white;
  padding-left: 6rem;
  cursor: pointer;
}

.user-start-quiz-button-container img {
  /* width: 5.8rem;
  height: 5.8rem;
  border-radius: 50px;
  color: white;
  background-color: white;
  margin-right: -7rem;
  z-index: 1;
  cursor: pointer; */
  /*  */
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50px;
  color: white;
  background-color: white;
  margin-right: -5.5rem;
  z-index: 1;
  cursor: pointer;
}

/*chrome*/
.user-text-image-container ::-webkit-scrollbar {
  height: 12px;
  width: 6px;
  background: #000;
}

.user-text-image-container ::-webkit-scrollbar-thumb {
  background: #7c7c72;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.user-text-image-container ::-webkit-scrollbar-corner {
  background: #000;
}

/* .course-container-main .user-slide-video-container,.course-container-main .video-player-container.normal {
  height: 560px;
} */

/* .user-slide-video-container .video-player-container.normal {
  height: 58rem;
} */

.user-slide-video-container .video-player-container.normal {
  height: 75vh;
}
/* start of navigation styles */

.user-nav-slides {
  display: flex;
  justify-content: center;
  /* margin: 3% 0; */
  /* position: absolute;
  bottom: -80px;
  left: 50%;
  padding: 0 20px;
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0); */
}

.nav-circle {
  width: 1rem;
  height: 1rem;
  background-color: #fff;
  border-radius: 50%;
  float: left;
  margin-right: 1rem;
}

.nav-circle.active {
  width: 2rem;
  height: 2rem;
  margin-top: -0.5rem;
}

.nav-circle-container {
  background-color: #333;
  border-radius: 10rem;
  padding: 1rem 2rem 1rem 2rem;
}

.nav-circle-container.slides {
  margin-top: 4rem;
}

.nav-arrow {
  width: 4rem;
  height: 3rem;
  cursor: pointer;

  margin-top: -1rem;
}

.nav-arrow.left {
  padding-right: 3rem;
}

.nav-arrow.right {
  padding-left: 2rem;
}

.nav-circle-inner,
.nav-circle-inner-first {
  display: flex;
  padding-top: 1rem;
}

/* end of navigation styles */

@media only screen and (max-width: 1550px) {
  .chapter-title {
    margin: 0 4% 1rem 4%;
  }

  .course-container-body {
    /* margin: 0 3.5%; */
    padding: 0 3.5%;
  }

  .user-slide-text {
    padding: 1.5% 2%;
    /* max-height: 48rem; */
  }

  .course-container-inner1 {
    margin-top: 1rem;
  }

  .course-title {
    padding: 1rem 0 0.3rem 2.3rem;
  }

  .user-nav-slides {
    bottom: -70px;
  }

  /* .user-text-image-container, .user-slide-video-container, .user-slide-video-container .video-player-container.normal {
    height: 55rem;
  } */
}

/* Tablet version */
@media only screen and (max-width: 915px) {
  .course-container-body {
    width: 107%;
    padding: 0 20px;
  }

  .course-details-burger-menu {
    display: block;
    position: fixed;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0;
    padding-left: 20px;
    z-index: 1;
  }

  .course-details-burger-menu-icon {
    width: 6rem;
    height: 6rem;
  }

  .course-details-burger-menu-title {
    font-weight: bold;
    font-size: 30px;
    color: #fff;
    margin-left: 10px;
  }

  .course-user-header {
    padding-right: 20px;
    height: 80px;
    z-index: 1;
  }

  .course-container-sidebar {
    display: none;
  }

  .course-title {
    margin-top: 80px;
  }

  .course-title.my-opacity {
    margin-top: 0;
  }

  .course-container-sidebar.show {
    display: block;
    width: 100%;
    position: absolute;
    z-index: 2;
    transition: ease-in-out;
  }

  .course-container-sidebar-header {
    width: 94.5%;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    position: fixed;
    background-color: #fff;
  }

  .course-container-sidebar-header-title {
    font-weight: bold;
    font-size: 30px;
  }

  .course-container-sidebar-header-close-button {
    height: 6rem;
    width: 6rem;
  }

  .sidebar-course-icon {
    margin-top: 100px;
  }

  .user-text-image-container {
    flex-direction: column-reverse !important;
    /* margin-top: 200px; */
    height: auto;
  }

  .course-container-body.hidden {
    opacity: 0.5;
  }

  .user-slide-text {
    height: auto;
    width: 98%;
    overflow-y: unset;
  }

  .user-slide-image-translation-container-2 {
    height: 90%;
    width: auto;
    display: flex;
    justify-content: center;
    max-width: 100%;
  }

  .user-translation-popup-dictionary {
    width: 100%;
    text-align: center;
    margin: 0;
    margin-top: 100px;
  }

  .translated-text-speaker-button {
    display: none;
  }

  .user-slide-navigator-start-quiz-button-container {
    width: 100%;
  }

  .user-nav-slides,
  .nav-circle-container {
    width: 100%;
  }

  .nav-circle-inner {
    display: flex;
    justify-content: space-between;
    padding-top: 0px;
  }

  .nav-circle-inner-first {
    display: flex;
    justify-content: space-between;
    padding-top: 0px;
  }

  .nav-arrow {
    margin: 0;
    width: 4.5rem;
    height: 3.5rem;
  }

  .nav-circle {
    display: none;
  }

  .add-shadow {
    box-shadow: 0px 10px 20px 20px rgb(0 0 0 / 20%);
  }

  .user-start-quiz-button {
    margin-right: 220px;
  }

  .course-details-slide-number {
    color: #fff;
    font-size: 22px;
  }
}

/* Phone version */
@media only screen and (max-width: 600px) {
  .course-title.my-opacity {
    margin-top: 0;
  }

  .course-container-sidebar-header-close-button {
    width: 50px;
    height: 50px;
  }

  .course-container-sidebar-header-title {
    font-size: 22px;
  }

  .course-details-burger-menu-icon {
    width: 50px;
    height: 50px;
  }

  .course-details-burger-menu-title {
    font-size: 22px;
  }

  .user-text-image-container {
    margin-top: 0;
  }

  .user-slide-text {
    width: 98%;
  }

  .user-slide-image-translation-container-2 {
    height: 450px;
    width: auto;
    display: flex;
    justify-content: center;
    max-width: 100%;
  }
  .user-slide-navigator-start-quiz-button-container {
    width: 100%;
  }

  .user-nav-slides,
  .nav-circle-container {
    width: 100%;
  }

  .nav-circle-inner {
    display: flex;
    justify-content: space-between;
    padding-top: 0px;
  }

  .nav-circle-inner-first {
    display: flex;
    justify-content: space-between;
    padding-top: 0px;
  }

  .nav-arrow {
    margin: 0;
    width: 4.5rem;
    height: 3.5rem;
  }

  .nav-circle {
    display: none;
  }

  .user-start-quiz-button {
    margin-right: 100px;
  }

  .course-details-slide-number {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 22px;
  }
}

@media only screen and (max-width: 455px){
  .user-start-quiz-button {
    font-size: 2rem !important;
  }
}

@media only screen and (max-width: 415px) {
  .course-container-body {
    padding: 0 20px;
  }

  .course-user-header {
    padding-right: 20px;
    height: 80px;
    z-index: 1;
  }

  .course-details-burger-menu {
    height: 80px;
    padding: 0;
    padding-left: 20px;
    z-index: 1;
  }

  .course-details-burger-menu-icon {
    width: 50px;
    height: 50px;
  }

  .course-details-burger-menu-title {
    font-weight: bold;
    font-size: 22px;
    color: #fff;
    margin-left: 10px;
  }

  .course-title {
    margin-top: 80px;
  }

  .course-title.my-opacity {
    margin-top: 0;
  }

  .user-text-image-container {
    flex-direction: column-reverse !important;
    height: auto;
    margin-top: 0;
  }

  .user-slide-image-translation-container-2 {
    height: 350px;
    width: auto;
    display: flex;
    justify-content: center;
    max-width: 100%;
  }

  .user-slide-text {
    height: auto;
    width: 98%;
  }

  .user-translation-popup-dictionary {
    margin-top: 100px;
  }

  .user-slide-navigator-start-quiz-button-container {
    width: 100%;
  }

  .user-nav-slides,
  .nav-circle-container {
    width: 100%;
  }

  .nav-circle-inner {
    display: flex;
    justify-content: space-between;
    padding-top: 0px;
  }

  .nav-circle-inner-first {
    display: flex;
    justify-content: space-between;
    padding-top: 0px;
  }

  .nav-arrow {
    margin: 0;
    width: 4.5rem;
    height: 3.5rem;
  }

  .nav-circle {
    display: none;
  }

  .course-container-sidebar-header {
    width: 89.5%;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    position: fixed;
    background-color: #fff;
  }

  .course-container-sidebar-header-title {
    font-weight: bold;
    font-size: 22px;
  }

  .course-container-sidebar-header-close-button {
    height: 50px;
    width: 50px;
  }

  .sidebar-course-icon {
    margin-top: 100px;
  }

  .nav-arrow.right {
    padding-left: 0;
  }

  .nav-arrow.left {
    padding-right: 0;
  }

  .user-start-quiz-button {
    font-size: 2rem !important;
    margin-right: 45px;
  }

  .course-details-slide-number {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 22px;
  }
}

@media only screen and (max-width: 340px) {
  .course-details-slide-number {
    font-size: 22px;
  }

  .user-start-quiz-button {
    font-size: 16px !important;
    padding-top: 12px;
  }
}

/* phone rotate */
@media only screen and (max-height: 599px) {
  .text.text-content{
    font-size: 30px;
  }

  .chapter-title-top{
    font-size: 30px;
    margin-top: 5px;
  }
}
