.home-page-container {
  display: flex;
}

.home-page-sidebar {
  margin-left: 1.04%;
  margin-right: 1.04%;
  width: 19.53%;
  position: sticky;
  height: 100vh;
  overflow: auto;
  top: 0px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.home-page-sidebar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.home-page-sidebar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.home-page-body {
  width: 88.5%;
  background: linear-gradient(#c4c6c6 55%, #3c3c3b 88%);
}

.home-page-body-container {
  padding: 60px 6.47% 0 6.47%;
}

.home-page-image {
  width: 100%;
  height: 600px;
}

.home-page-title {
  border-radius: 100px;
  font-size: 64px;
  font-weight: bold;
  padding: 3% 4.3%;
  /* color: black; */
  background-color: white;
  max-width: 589px;
}

.home-page-vid-container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 9%;
}

.home-page-video-container {
  width: 100%;
}

.home-page-vid-item {
  width: 48.4%;
  margin-right: 3.16%;
  display: flex;
  border-radius: 45px;
  margin-top: 40px;
  position: relative;
}

.home-page-vid-item:nth-child(1) .home-page-vid-title {
  color: #ff2519;
  font-weight: bold;
}

.home-page-vid-item:nth-child(2) .home-page-vid-title {
  color: #005a70;
  font-weight: bold;
}

.home-page-vid-item:nth-child(3) .home-page-vid-title {
  color: #475a07;
  font-weight: bold;
}

.home-page-vid-item:nth-child(4) .home-page-vid-title {
  color: #942f00;
  font-weight: bold;
}

.home-page-vid-item:nth-child(2n + 2) {
  margin-right: 0;
}

.home-page-th-1,
.home-page-th-1-full {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 45px;
  height: 100%;
  box-shadow: 0px 0px 15px 15px rgba(0, 0, 0, 0.03);
}

.home-page-vid-title {
  position: absolute;
  top: 30px;
  left: 60px;
  font-size: 40px;
  max-width: 380px;
}

.home-page-play-container {
  position: absolute;
  left: 27px;
  bottom: 20px;
  width: 16%;
}

/* Under Construction */
.home-page-under-construction-div {
  margin-top: 130px;
  padding: 30px;
  font-size: 34px;
  border-radius: 45px;
  background-color: #3c3c3b;
  color: white;
  font-weight: bold;
}

.home-page-under-construction-div p {
  margin-bottom: -40px;
}

.home-page-under-construction-div:after {
  content: "";
  position: relative;
  margin-left: 225px;
  top: 17rem;
  border-right: 0px solid #3c3c3b;
  border-top: 110px solid #3c3c3b;
  border-left: 130px solid transparent;
}

.home-page-under-construction-img {
  width: 100%;
}

/* Start Footer */

.home-page-footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* padding-top: 2%; */
  padding-bottom: 2%;
}

.home-page-footer-section {
  color: white;
  margin-top: 25px;
}

.home-page-footer-title {
  font-weight: bold;
  color: white;
}

/* End Footer */
.home-page-video-container .video-player-container.small {
  height: 100%;
}

.home-page-video-container .react-player-vid video {
  border-radius: 45px;
}

.home-page-video-container .controlsWrapper {
  border-radius: 45px;
}

.home-page-video-container .video-controls-container {
  margin: 0 11px 11px 11px;
}

.home-page-video-container .video-player-container.normal {
  height: 100% !important;
}

.home-page-temporary-text-phone-only {
  display: none;
}

/* for the last footer section */
.links {
  font-weight: normal !important;
  cursor: pointer;
}

.home-page-sidebar-header {
  display: none;
}

.home-page-burger-menu {
  display: none;
}

/* Tablet version */
@media only screen and (max-width: 1550px) {
  .home-page-title {
    border-radius: 60px;
    font-size: 42px;
    padding: 3% 2.5%;
    max-width: 373px;
  }

  .home-page-vid-title {
    left: 27px;
    font-size: 30px;
  }

  .home-page-footer-section.column1 {
    padding-left: 26px;
  }
}

/* Phone version */
@media only screen and (max-width: 915px) {
  .home-page-sidebar {
    display: none;
  }
  .home-page-body {
    width: 100%;
  }
  .user-help-logo {
    display: none;
  }
  .home-page-title {
    font-size: 44px;
    text-align: center;
    margin: 0 65px;
    margin-top: 120px;
  }
  .home-page-temporary-text-phone-only {
    display: block;
    font-size: 34px;
    font-weight: bold;
    line-height: 1.2;
    margin-top: 65px;
  }
  .home-page-vid-item {
    width: 100%;
  }
  .home-page-vid-title {
    top: 20px;
    font-size: 24px;
  }
  .home-page-vid-item:nth-child(2n + 2) {
    margin-right: 3.16%;
  }
  .home-page-footer {
    padding-top: 5%;
    flex-direction: column;
  }
  .home-page-footer-section {
    color: white;
  }

  .home-page-footer-title {
    font-weight: bold;
    color: white;
  }
  .home-page-under-construction-div:after {
    content: "";
    position: relative;
    margin-left: 175px;
    top: 12rem;
    border-right: 0px solid #3c3c3b;
    border-top: 80px solid #3c3c3b;
    border-left: 100px solid transparent;
  }
  .video-controls-container {
    flex-wrap: nowrap;
    padding: 0 10px;
    align-items: center;
  }
  .controlsWrapper:hover .video-controls-container {
    opacity: 1;
    padding-top: 11px;
  }

  .home-page-burger-menu {
    width: 95%;
    height: 80px;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    z-index: 3;
    background-color: #acacac;
  }

  .home-page-burger-menu-icon {
    width: 6rem;
    height: 6rem;
  }

  .home-page-burger-menu-title {
    font-weight: bold;
    font-size: 30px;
    color: #fff;
    margin-left: 15px;
  }

  .home-page-sidebar.show {
    display: block;
    position: absolute;
    z-index: 3;
    width: 100%;
    background-color: #fff;
    margin-left: 0;
    /* padding: 0 20px; */
    height: 100%;
  }

  .home-page-sidebar-header {
    width: 95%;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    background-color: #fff;
    padding: 0 20px;
  }

  .home-page-sidebar-header-title {
    font-weight: bold;
    font-size: 30px;
  }

  .home-page-sidebar-header-close-button {
    width: 6rem;
    height: 6rem;
  }

  .home-page-container-hidden {
    height: 100%;
    overflow: hidden;
  }

  .add-shadow {
    box-shadow: 0px 10px 20px 20px rgb(0 0 0 / 20%);
  }

  .home-page-th-1-full {
    max-height: 420px !important;
    top: 30% !important;
  }
}

@media only screen and (max-width: 600px) {
  .home-page-sidebar-header-title {
    font-size: 22px;
  }

  .home-page-sidebar-header-close-button {
    width: 50px;
    height: 50px;
  }

  .home-page-burger-menu-title {
    font-size: 22px;
  }

  .home-page-burger-menu-icon {
    width: 50px;
    height: 50px;
  }

  .home-page-th-1-full {
    max-height: 242px !important;
    top: 32% !important;
  }
}

@media only screen and (max-width: 430px) {
  .home-page-title {
    font-size: 34px;
    text-align: center;
    margin: 0 60px;
    margin-top: 50px;
  }
  .home-page-temporary-text-phone-only {
    display: block;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;
    margin-top: 65px;
  }
  .home-page-under-construction-div {
    font-size: 24px;
  }
  .home-page-vid-title {
    font-size: 22px;
    top: 15px;
  }

  .home-page-sidebar-header {
    width: 90%;
  }

  .home-page-th-1-full {
    max-height: 190px !important;
    top: 38% !important;
  }
}

@media only screen and (max-width: 360px) {
  .home-page-title {
    font-size: 22px;
    text-align: center;
    margin: 0 0;
    margin-top: 100px;
    padding-right: 0;
    padding-left: 0;
  }
}

/* phone rotate */
@media only screen and (max-height: 599px) {
  .home-page-vid-item {
    max-height: 65vh;
  }

  .home-page-th-1-full {
    top: 0px !important;
  }
}
