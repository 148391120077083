.change-password.modal-content {
    padding: 14px 40px 14px 28px;
    width: 600px;
    z-index:6 ;
    height:auto;
}

.popup-buttons {
    display: flex;
    justify-content: flex-end;
}