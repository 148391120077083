.slide-text-image-container {
  display: flex;
}

.slide-text-container {
  flex: 1;
  margin-bottom: -6rem;
}

.slide-textarea textarea {
  width: 99%;
  height: 45vh;
  font-size: 2rem;
}

.slide-image-container {
  flex: 1;
}

.slide-image {
  width: 95%;
  height: 45vh;
  border-radius: 25px;
  margin-left: 1rem;
}

.rdw-editor-main {
  overflow: auto;
  box-sizing: border-box;
  min-height: 40rem !important;
  background-color: white;
}

.rdw-block-dropdown {
  width: 130px !important;
}
