.lds-ring {
  position: absolute;
  top: 47%;
  left: 50%;
  padding: 0 20px;
  -moz-transform: translate(-50%, -47%);
  -ms-transform: translate(-50%, -47%);
  -o-transform: translate(-50%, -47%);
  -webkit-transform: translate(-50%, -47%);
  transform: translate(-50%, -47%);
  z-index: 5;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--primaryBackground);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--primaryBackground) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Tablet version */
@media only screen and (max-width: 800px){
  .lds-ring{
    left: 45%;
  }
}

/* phone version */
@media only screen and (max-width: 400px){
  .lds-ring{
    left: 40%;
  }
}


