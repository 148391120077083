.video-player-container {
  position: relative;
  height: 500px;
}

.video-player-container.big {
  height: 600px;
}

.video-player-container.normal {
  height: 300px;
}

.video-player-container.small {
  height: 183px;
}

video::cue {
  display: none !important;
  visibility: hidden;
}

#controller {
  width: 100%;
  top: 0;
  bottom: 0;
  position: absolute;
  cursor: pointer;
  z-index: 2;
}


.react-player-vid {
  width: 100% !important;
  height: 100% !important;
  position: relative;
}

.learning-module-name-above-video {
  margin: 1rem;
  border-radius: 50px;
  position: absolute;
  font-size: 22px;
  font-weight: bold;
  padding: 1rem 2rem;
  z-index: 3;
  background-color: white;
}

.current-caption{
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  color: #fff;
  border-radius: 45px;
  padding: 10px 20px;
}

#react-player:hover > .current-caption {
  bottom: 90px;
}

body.enable-contrast .current-caption {
  background-color: #f1c40f;
  color: black;
}

/* phone version */
@media only screen and (max-width: 600px) {

  #react-player:hover > .current-caption {
  bottom: 85px !important;
}
  .current-caption {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    bottom: 2px !important;
  }

}

@media only screen and (max-width: 400px) {

    #react-player:hover > .current-caption {
  bottom: 85px !important;
}
  .current-caption {
    font-size: 12px;
    bottom: 1px !important;
  }

}
