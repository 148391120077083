.learning-module-section {
  display: flex;
}

.learning-module-top-icon {
  width: 21rem;
  min-height: 21rem;
  margin-right: 3px;
  cursor: pointer;
}

.learning-module-top-name {
  background-color: white;
  color: #26a0c0;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding: 5.5rem 5rem;
  height: 100px;
}

.learning-module-top-name h1 {
  font-size: 5rem;
  font-weight: bolder;
}

.learning-module-top-name h3 {
  font-size: 1.8rem;
  font-weight: bold;
  /* add margin-bottom:3rem */
  margin-bottom: 3rem;
}

.disabled {
  background-color: #3c4548;
  opacity: 0.3;
}

.icon-input {
  background-color: transparent;
  padding: 0;
}

.icon-input p {
  cursor: pointer;
  color: #333;
  position: absolute;
  bottom: 1rem;
  left: 28%;
}

.icon-input input {
  display: none;
  background-color: transparent;
}

.learning-module-thumbnail-video-container {
  display: flex;
}

.learning-module-thumbnail-container {
  cursor: pointer;
}

.react-player-vid {
  width: 100% !important;
}

.learning-module-top-thumbnail {
  width: 15rem;
  height: 15rem;
  padding: 3rem;
  background-color: #26a0c0;
  background-color: white;
  cursor: pointer;
  margin-right: 3px;
}

.learning-module-top-thumbnail p {
  width: 20rem;
}

.learning-module-video-container {
  flex: 4;
  text-align: center;
}

.react-player-vid.video-background-color {
  width: 708px;
  height: 210px;
}

.learning-module-top-video {
  text-align: center;
  width: 15rem;
  height: 15rem;
  padding: 3rem;
  cursor: pointer;
}

.learning-module-top-video p {
  width: 20rem;
}

.thumbnail-input {
  background-color: transparent;
  padding: 0;
}

.thumbnail-input p {
  text-align: center;
  margin-top: -3.5rem;
  width: 20rem;
  cursor: pointer;
}

.video-input {
  background-color: transparent;
  padding: 0;
}

.video-input p {
  text-align: center;
  margin-top: -4rem;
  cursor: pointer;
  color: #333;
}

.learning-module-video-container .edit-video {
  cursor: pointer;
  color: #fff;
}
