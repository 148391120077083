.create-edit-module-subject-container {
  display: flex;
}

.create-edit-module-subject-sidebar {
  margin-left: 1.04%;
  margin-right: 1.04%;
  width: 19.53%;
}

.create-edit-module-subject-body {
  flex: 4;
  margin-left: 1rem;
  background-color: #ececec;
  padding: 3rem 5rem;
}

.OS-filters-section {
  background-color: white;
  color: #26a0c0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 5rem;
}

.OS-filters-section h2 {
  font-size: 3rem;
  font-weight: bold;
  background-color: #ececec;
  border-radius: 5rem;
  padding: 0.5rem 1rem;
  margin: 2rem;
}

.OS-filters-section h3 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-left: 17rem;
}

.OS-filters-platform {
  display: flex;
  justify-content: space-evenly;
}

.editor-one-subject {
  margin: 8rem 0;
  width: 100%;
}

.editor-subject-name-container {
  background-color: white;
  padding: 0.5rem;
  display: flex;
  align-items: center;
}

.editor-subject-name {
  background-color: white;
  width: 100%;
  font-size: 30px;
  font-weight: bold;
  color: #26a0c0;
}

.editor-subject-delete-icon {
  width: 5rem;
  height: 5rem;
  cursor: pointer;
}

.courses-list {
  display: flex;
  flex-wrap: wrap;
}

.my-one-course {
  width: 27.8%;
  background-color: #fff;
  margin-right: 4%;
  margin-right: 2%;
  border-radius: 20px;
  margin-top: 3rem;
  padding: 2rem;
}

.my-one-course:nth-child(3n + 3) {
  margin-right: 0 !important;
}

.course-icon {
  width: 15rem;
  height: 15rem;
}

.course-desc {
  font-size: 2rem;
  font-weight: bold;
}

.course-desc-container,
.course-icon-container {
  width: 50%;
  cursor: pointer;
}

.my-new-one-course {
  width: 31%;
  background-color: #ececec;
  border: solid 5px white;
  border-radius: 20px;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 189px;
}

.new-course-container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  cursor: pointer;
}

.new-course-icon {
  width: 12rem;
  height: 12rem;
  padding-right: 2rem;
}

.course-status-icon {
  position: absolute;
  width: 5.5rem;
  height: 5.5rem;
  bottom: -22px;
  right: 17px;
}

.my-container {
  display: flex;
  flex-wrap: wrap;
}

.my-size {
  width: 25%;
  background-color: #fff;
  margin-right: 2%;
  border-radius: 20px;
  margin-top: 3rem;
  padding: 2rem;
}

.in-edit-box {
  background-color: rgb(250, 249, 215);
}

.published-box {
  background-color: rgb(207, 228, 186);
}

.deleted-box {
  background-color: rgb(250, 215, 215);
}

.learning-module-icon-container .icon-input p {
  bottom: unset;
  left: unset;
  margin-top: -3.5rem;
  text-align: center;
  position: relative;
}

@media only screen and (max-width: 2560px) and (min-height: 1440px) {
  .create-edit-module-subject-sidebar {
    flex-basis: 390px;
  }
}

@media only screen and (max-width: 1550px) {
  .create-edit-module-subject-sidebar {
    flex-basis: 296px;
  }
}

@media screen and (max-width: 1300px) {
  .my-one-course:nth-child(2n + 2) {
    margin-right: 0 !important;
  }
}

@media only screen and (max-width: 1280px) and (min-height: 800px) {
  .create-edit-module-subject-sidebar {
    flex-basis: 218px;
  }
}
