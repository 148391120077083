.user-dictionary-container {
  display: flex;
  width: 100%;
  min-height: 100vh;
}

.user-dictionary-sidebar {
  margin-left: 1.04%;
  margin-right: 1.04%;
  width: 19.53%;
  position: sticky;
  height: 100vh;
  overflow: auto;
  top: 0px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.user-dictionary-sidebar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.user-dictionary-sidebar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.user-dictionary-body-container {
  width: 88.5%;
  background-color: rgb(247, 247, 247);
}

.user-dictionary-header {
  background-color: #c4c6c6;
  height: 300px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.user-dictionary-sidebar-header {
  display: none;
}

.user-dictionary-burger-menu {
  display: none;
}

.user-dictionary-title {
  margin-left: 6.6%;
  padding: 20px 40px;
  border-radius: 60px;
  font-size: 40px;
  font-weight: bold;
  background-color: white;
}

.user-dictionary-body {
  padding-right: 6.47%;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.user-dictionary-terms-side {
  flex: 1;
  padding-left: 9%;
  margin-right: 2%;
  height: 60vh;
  overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.user-dictionary-terms-side::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.user-dictionary-terms-side {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.user-dictionary-speaker-character-container {
  display: flex;
}

.user-dictionary-character {
  display: block;
  font-weight: bold;
  font-size: 40px;
  margin-top: 60px;
  margin-left: 40px;
  margin-bottom: 25px;
}

.user-dictionary-speaker-icon {
  width: 50px;
  height: 50px;
  margin-left: -6rem;
  cursor: pointer;
  display: block;
  font-weight: bold;
  margin-top: 60px;
  margin-bottom: 25px;
  z-index: 3;
}

.user-dictionary-terms {
  background-color: white;
  padding: 20px 40px;
  border-radius: 25px;
}

.user-dictionary-one-translationTerm {
  cursor: pointer;
}

.user-dictionary-translation-container {
  flex: 1;
}

.user-dictionary-popup {
  margin-left: 2%;
  margin-top: -20rem;
  min-height: 75vh;
  max-height: 75vh;
  border-radius: 25px;
  box-shadow: 0px 0px 15px 15px rgba(0, 0, 0, 0.3);
  opacity: 1;
  background-color: white;
  overflow: auto;
}

.translation-text-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.translated-term-header {
  padding: 35px 40px;
  flex: 3;
}

.translated-term-header p {
  font-weight: bold;
}

.translated-term-slide-popup {
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
}

.translation-text-popup-header2 {
  padding: 20px 20px;
}

.translated-text-speaker-button {
  cursor: pointer;
  width: 50px;
  padding: 0px 20px;
  flex: 1;
}

.translated-text-close-button {
  cursor: pointer;
  width: 50px;
}

.translated-text-user-popup {
  padding: 0 40px;
  padding-bottom: 40px;
  line-height: 125%;
}
/* end dictionary popup */

/* Tablet version */

/* @media only screen and (max-width: 912px){
  .user-dictionary-terms-side{
    height: 75vh;
  }
  .user-dictionary-popup{
    margin-top: 0;
  }
  .translated-text-speaker-button{
    display: none;
  }

  .user-help-logo{
    display: block;
  }
} */

@media only screen and (max-width: 915px) {
  .user-dictionary-sidebar {
    display: none;
  }

  .user-dictionary-sidebar.show {
    display: block;
    position: absolute;
    z-index: 2;
    width: 100%;
    background-color: #fff;
    margin-left: 0;
    height: 100%;
  }

  .user-dictionary-sidebar-header {
    width: 95%;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    background-color: #fff;
    padding: 0 20px;
  }

  .user-dictionary-sidebar-header-title {
    font-weight: bold;
    font-size: 30px;
  }

  .user-dictionary-sidebar-header-close-button {
    width: 6rem;
    height: 6rem;
  }

  .user-dictionary-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
    background-color: #acacac;
    margin-bottom: 0;
  }

  .user-dictionary-burger-menu {
    width: 95%;
    height: 80px;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 1;
    background-color: #acacac;
  }

  .user-dictionary-burger-menu-icon {
    width: 6rem;
    height: 6rem;
  }

  .user-dictionary-burger-menu-title {
    font-weight: bold;
    font-size: 30px;
    color: #fff;
  }

  .user-dictionary-title {
    margin-left: 0;
    font-size: 36px;
  }

  .user-dictionary-body {
    padding: 0 20px;
    padding-bottom: 20px;
    display: block;
    background-color: rgb(230, 230, 230);
  }

  .user-dictionary-body-container {
    width: 100%;
  }

  .user-dictionary-terms-side {
    padding-left: 0;
    margin-right: 0;
    height: auto;
  }

  .user-dictionary-speaker-icon {
    display: none;
  }

  .user-dictionary-translation-container {
    position: absolute;
    margin-right: 20px;
    width: 93%;
    z-index: 3;
    position: fixed;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .user-dictionary-popup {
    height: auto;
    max-height: 65vh;
    min-height: auto;
  }

  .user-help-logo {
    display: none;
  }

  .add-shadow {
    box-shadow: 0px 10px 20px 20px rgb(0 0 0 / 20%);
  }

  .translated-text-speaker-button {
    display: none;
  }

  .user-dictionary-container.translated {
    height: 100%;
    overflow: hidden;
  }
}

/* phone version */

@media only screen and (max-width: 600px) {
  .user-dictionary-sidebar-header {
    width: 92%;
  }

  .user-dictionary-sidebar-header-title {
    font-size: 22px;
  }

  .user-dictionary-sidebar-header-close-button {
    width: 50px;
    height: 50px;
  }

  .user-dictionary-burger-menu {
    width: 92%;
  }

  .user-dictionary-burger-menu-title {
    font-size: 22px;
  }

  .user-dictionary-burger-menu-icon {
    width: 50px;
    height: 50px;
  }

  .user-dictionary-title {
    font-size: 28px;
  }

  .user-dictionary-translation-container {
    margin-right: 20px;
    top: 65%;
    width: 92%;
  }
}

@media only screen and (max-width: 415px) {
  .user-dictionary-sidebar-header {
    width: 90%;
  }

  .user-dictionary-burger-menu {
    width: 90%;
  }

  .user-dictionary-translation-container {
    margin-right: 20px;
    top: 65%;
    width: 90%;
  }

  .translated-term-header {
    font-size: 24px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .translation-text-popup-header2 {
    padding-left: 0;
  }

  .translated-text-user-popup {
    font-size: 24px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .user-dictionary-container.translated {
    height: 100%;
    overflow: hidden;
  }
}

@media only screen and (max-width: 300px) {
  .user-dictionary-sidebar-header {
    width: 86%;
  }

  .user-dictionary-burger-menu {
    width: 86%;
  }

  .user-dictionary-translation-container {
    margin-right: 20px;
    top: 64%;
    width: 86%;
  }

  .translated-text-user-popup {
    font-size: 22px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .translated-term-header {
    padding-left: 20px;
  }

  .translated-term-slide-popup {
    font-size: 18px;
  }

  .translated-text-close-button {
    width: 35px;
    height: 35px;
    padding-right: 10px;
  }

  .translated-text-user-popup {
    font-size: 18px;
  }
}

/* on phone rotate */
@media only screen and (max-height: 599px){
  .user-dictionary-popup{
    margin-top: 0px;
  }

  .user-dictionary-speaker-icon {
    display: none;
  }

  .translated-text-speaker-button {
    display: none;
  }
}