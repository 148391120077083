.footer-pages-main-container {
  background-color: #e5ebeb;
  /* width: 100%; */
}

.footer-pages-main {
  padding: 50px 20%;
}

.footer-pages-header-container {
  margin-top: 50px;
  margin-bottom: 80px;
}

/* Header */
.user-close-logo {
  position: fixed;
  right: 20px;
  top: 20px;
  width: 50px;
  height: 50px;
  z-index: 3;
  cursor: pointer;
}

.user-footer-help-logo {
  position: fixed;
  right: 80px;
  top: 20px;
  width: 50px;
  height: 50px;
  z-index: 3;
  cursor: pointer;
}

/* Title */
.footer-page-title {
  border-radius: 100px;
  font-size: 34px;
  font-weight: bold;
  padding: 3% 4.3%;
  background-color: white;
  max-width: 589px;
}

/* speaker icon */
.footer-pages-speaker-icon-p1,
.footer-pages-speaker-icon-p2,
.footer-pages-speaker-icon-p3,
.footer-pages-speaker-icon-p4,
.footer-pages-speaker-icon-p5,
.footer-pages-speaker-icon-p6,
.footer-pages-speaker-icon-p7,
.footer-pages-speaker-icon-p8,
.footer-pages-speaker-icon-p9 {
  width: 50px;
  height: 50px;
  margin-left: -6.5rem;
  margin-right: 1.5rem;
  cursor: pointer;
  display: block;
  font-weight: bold;
  z-index: 33;
}

/* Paragraph one */
.about-us-p1-main {
  display: flex;
  margin-bottom: 40px;
}
.about-us-p1-main h1 {
  font-weight: bold;
}
.about-us-p1 {
  width: 100%;
}

/* Paragraph two */
.about-us-p2-main {
  display: flex;
  margin-bottom: 40px;
}
.about-us-p2-main h1 {
  font-weight: bold;
}
.about-us-p2 {
  width: 100%;
  overflow: auto;
}

/* Paragraph three */
.about-us-p3-main {
  display: flex;
  margin-bottom: 40px;
}
.about-us-p3-main h1 {
  font-weight: bold;
}
.about-us-p3 {
  width: 100%;
}

/* Paragraph four */
.about-us-p4-main {
  display: flex;
  margin-bottom: 40px;
}
.about-us-p4-main h1 {
  font-weight: bold;
}
.about-us-p4 {
  width: 100%;
}

/* Paragraph five */
.about-us-p5-main {
  display: flex;
  margin-bottom: 40px;
}
.about-us-p5-main h1 {
  font-weight: bold;
}
.about-us-p5 {
  width: 100%;
}

/* Paragraph six */
.about-us-p6-main {
  display: flex;
  margin-bottom: 40px;
}
.about-us-p6-main h1 {
  font-weight: bold;
}
.about-us-p6 {
  width: 100%;
}

/* Paragraph seven */
.about-us-p7-main {
  display: flex;
  margin-bottom: 40px;
}
.about-us-p7-main h1 {
  font-weight: bold;
}
.about-us-p7 {
  width: 100%;
}

/* Paragraph eight */
.about-us-p8-main {
  display: flex;
  margin-bottom: 40px;
}
.about-us-p8-main h1 {
  font-weight: bold;
}
.about-us-p8 {
  width: 100%;
}

/* Paragraph nine */
.about-us-p9-main {
  display: flex;
  margin-bottom: 40px;
}
.about-us-p9-main h1 {
  font-weight: bold;
}
.about-us-p9 {
  width: 100%;
}

.footer-page-header-title {
  display: none;
}

/* Phone version */
@media only screen and (max-width: 800px) {
  .footer-pages-main-container {
    background-color: #e5ebeb;
    /* width: 100%; */
  }

  .footer-pages-main {
    /* padding: 50px 20%; */
    padding: 0;
    margin: 0 10%;
  }
  .footer-pages-header-container {
    margin: 0 10%;
    padding-top: 100px;
    margin-top: 0;
    margin-bottom: 80px;
  }
  /* speaker icon */
  .footer-pages-speaker-icon-p1,
  .footer-pages-speaker-icon-p2,
  .footer-pages-speaker-icon-p3,
  .footer-pages-speaker-icon-p4,
  .footer-pages-speaker-icon-p5,
  .footer-pages-speaker-icon-p6,
  .footer-pages-speaker-icon-p7,
  .footer-pages-speaker-icon-p8,
  .footer-pages-speaker-icon-p9 {
    display: none;
  }

  .footer-page-header {
    width: 100%;
    height: 90px;
    background-color: #e5ebeb;
    position: fixed;
    left: 0;
  }

  .footer-page-header-title {
    font-size: 34px;
    font-weight: bold;
    margin-top: 20px;
    margin-left: 15px;
    display: none;
  }
  .add-shadow {
    box-shadow: 0px 10px 20px 20px rgb(0 0 0 / 20%);
  }

  .title-show {
    display: block;
  }
}

@media only screen and (max-width: 600px) {
  /* Title */
  .footer-page-title {
    border-radius: 100px;
    font-size: 34px;
    font-weight: bold;
    padding: 3% 4.3%;
    background-color: white;
    max-width: 589px;
    margin-left: -10%;
  }

  .footer-page-header-title {
    font-size: 26px;
    margin-top: 25px;
  }
}

@media only screen and (max-width: 400px) {
  /* Title */
  .footer-page-title {
    border-radius: 100px;
    font-size: 34px;
    font-weight: bold;
    padding: 3% 4.3%;
    background-color: white;
    max-width: 589px;
    margin-left: -22%;
  }

  .footer-page-header-title {
    font-size: 20px;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 375px) {
  .footer-page-title {
    font-size: 30px;
  }
}
