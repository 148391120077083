.slide-image-video-upload-section {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-evenly;
  align-items: center;
}

.slide-easy-normal-lang {
  text-align: center;
}

.slide-select-languages-div .normal-lang {
  background-color: black;
  color: white;
  padding: 1rem;
  font-size: 2rem;
  font-weight: bold;
  border-radius: 25px;
  border: 0;
}

.slide-select-languages-div .easy-lang {
  color: green;
  padding: 1rem;
  font-size: 2rem;
  font-weight: bold;
  border: 0;
  cursor: pointer;
}