.course-publishing-section {
  width: 93%;
  text-align: center;
  margin-top: 3rem;
}

.course-publishing-div {
  display: inline;
  padding: 2rem 1rem 1.5rem 1rem;
  background-color: #ececec;
  border-radius: 25px;
}

.course-publishing-div .ready-to-be-published {
  background-color: black;
  color: white;
  padding: 1rem;
  font-size: 2rem;
  font-weight: bold;
  border-radius: 25px;
  border: 0;
}

.course-publishing-div .publish-course-button {
  color: green;
  padding: 1rem;
  font-size: 2rem;
  font-weight: bold;
  border: 0;
  cursor: pointer;
}

.publish-course-button:hover{
  background-color: transparent;
}