/* start user sidebar */
.sidebar-list-content {
  padding-left: 0rem;
  list-style: none;
}

.sidebar-box {
  border-radius: 12px;
  /* padding: 1rem; */
  /* margin between each sidebar box */
  margin: 15px 0;
  background-color: #f4f4f4;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  line-height: 1;
}

.sidebar-box h3 {
  /* padding between learning module names and the border */
  padding: 15px 0;
  padding-right: 15px;
  line-height: 1;
}

.sidebar-box-selected {
  border-radius: 12px;
  /* margin between each sidebar box */
  margin: 15px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  line-height: 1;
}

.sidebar-box-selected.contrast {
  color: #333;
}

.sidebar-box-selected h3 {
  /* padding between learning module names and the border */
  padding: 15px 0;
  padding-right: 15px;
}

.start-end-button {
  margin: 40px 0;
}

.start-end-button.in-landing {
  background-color: #3c3c3b;
  color: #fff;
}

.start-end-button.in-landing-contrast {
  background-color: #fff;
}

.start-end-button.in-landing-contrast .text-content {
  color: black;
}

.sidebar-icon {
  /* because they have from the designer some padding so we put them directly 70px */
  width: 20.89%;
  height: 8.89%;
  /* padding-right: 0.5rem; */
}

/* end user sidebar */

.publish {
  background-color: rgb(43, 198, 43);
}

.in-edit {
  background-color: rgb(255, 255, 0);
}

/* design for sidebar boxes when they aren't clicked */
.publish-not-clicked {
  background-color: rgb(207, 228, 186);
}

.inEdit-not-clicked {
  background-color: rgb(250, 249, 215);
}

/* tablet version */
@media only screen and (max-width: 915px) {
  .sidebar-box {
    margin-left: 20px;
    margin-right: 20px;
  }

  .start-end-button {
    margin-top: 100px;
  }

  .sidebar-box-selected {
    margin-left: 18px;
    margin-right: 18px;
  }

  .sidebar-top-item {
    margin-top: 100px;
  }
}

/* phone rotate */
@media only screen and (max-height: 599px) {
  .sidebar-box .text-content {
    font-size: 30px !important;
  }
}
