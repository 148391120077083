@media only screen and (max-width: 915px) {
  .mobile-translation-popup-container {
    flex: 1;
    position: fixed;
    margin-right: 20px;
    width: 93%;
    z-index: 3;
    position: fixed;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .mobile-translation-text-popup {
    margin-left: 2%;
    margin-top: -20rem;
    min-height: auto;
    max-height: 75vh;
    border-radius: 25px;
    box-shadow: 0px 0px 15px 15px rgba(0, 0, 0, 0.3);
    opacity: 1;
    background-color: white;
    overflow: auto;
    height: auto;
  }

  .mobile-translation-text-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-translated-term-header {
    padding: 35px 40px;
    flex: 3;
  }

  .mobile-translated-term-header p {
    font-weight: bold;
  }

  .mobile-translated-term-slide-popup {
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
  }

  .mobile-translation-text-popup-header2 {
    padding: 20px 20px;
  }

  .mobile-translated-text-close-button {
    cursor: pointer;
    width: 50px;
  }

  .mobile-translated-text-user-popup{
    padding: 20px;
  }
}

@media only screen and (max-width: 600px){
    .mobile-translation-popup-container{
        top: 65%;
    }
}

@media only screen and (max-height: 400px){
    .mobile-translation-popup-container{
        top: 75%;
    }
}
