.cookies-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #ffffff;
  padding: 40px;
  border-radius: 15px;
  overflow: auto;
  max-height: 100vh;
  z-index: 5;
}

/*chrome*/
/* the container or background of the scroll bar inside the category item container */
.cookies-modal-content::-webkit-scrollbar {
  width: 10px;
  border-bottom-right-radius: 45px;
  border-top-right-radius: 45px;
}

/* the scroll bar itself  */
.cookies-modal-content::-webkit-scrollbar-thumb {
  border-bottom-right-radius: 45px;
  border-top-right-radius: 45px;
  background: rgb(218, 213, 213);
}

.cookies-popup-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.cookies-popup-header h1 {
  font-size: 34px;
  font-weight: bold;
}

.cookies-speaker-button {
  width: 50px;
  cursor: pointer;
}

.cookies-image-container {
  text-align: center;
  /* margin: 20px; */
}

.cookies-image {
  width: 130px;
}

.cookies-paragraph-2 {
  border-radius: 12px;
  background-color: whitesmoke;
  padding: 20px;
  margin-bottom: 20px;
}

.cookies-paragraph-3 {
  border-radius: 12px;
  background-color: whitesmoke;
  padding: 20px;
}

.cookies-popup-buttons-container {
  margin-top: 20px;
  text-align: center;
}

.cookies-popup-buttons-container button {
  color: white;
  background-color: black;
  padding: 15px 20px;
  border-radius: 50px;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
}

.cookies-popup-link {
  cursor: pointer;
  text-decoration: underline;
}

@media only screen and (max-width: 1300px) {
  .cookies-modal-content {
    top: 46%;
    max-height: 70vh;
    border-radius: 15px;
  }
}

@media only screen and (max-height: 600px) {
  .cookies-modal-content {
    top: 42%;
    max-height: 65vh;
  }
}

@media only screen and (max-width: 800px) {
  .cookies-speaker-button {
    display: none;
  }

  .cookies-popup-header h1 {
    font-size: 30px;
    font-weight: bold;
  }
}

@media only screen and (max-height: 800px) {
  .cookies-modal-content {
    top: 49%;
    max-height: 65vh;
  }
}

@media only screen and (max-height: 400px) {
  .cookies-modal-content {
    top: 44%;
    max-height: 50vh;
  }
}

@media only screen and (max-width: 450px) {
  .cookies-speaker-button {
    display: none;
  }

  .cookies-popup-header h1 {
    font-size: 30px;
    font-weight: bold;
  }
}
